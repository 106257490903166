import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import Product from "../api/ProductTable";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.min.css";
import { Button } from "primereact/button";
import LineChart from "./Linechart";

// import "primereact/resources/themes/lara-dark-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

// import { useRouter } from "next/navigation";
import { useRouter } from "next/router";

import Marc from "./Marcque";
// import MonthMarc from "../MonthMar";

import TeamBillability from "./TeamBillability";
import MixpanelRecord from "../MixpanelEvent";

import getCookieValue from "../GetCookie";
export default function Revenue() {
  const router = useRouter();
  const [visible, setVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [ytd, setYtd] = useState(0);
  const [proj, setProj] = useState(0);
  const formatCurrency = (value, val) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });
  };

  const {
    query: { username, policy },
  } = router;

  const props = {
    username,
    policy,
  };

  const name = props.username;

  const headers = { Authorization: "Bearer " + getCookieValue("token") };
  useEffect(() => {
    console.log("priya");
    // let cook = document.cookie.slice(2)
    // fetch("http://52.146.42.10:9992/api/v1/harvis/getFinalDetails")
    fetch("http://52.146.42.10:9992/api/v1/harvis/getFinalDetails", { headers })
      .then((response) => response.json())
      .then((data) => {
        if (data.name == "TokenExpiredError") {
          router.push(
            {
              pathname: "/Login",
            },
            "/Login"
          );
        } else {
          setProducts(data);

          let total = 0;
          for (let sale of data) {
            if (sale.CompanyName === "Total") {
              setYtd(sale.YtdAchieved);
              setProj(sale.ProjectedYr);
            }
          }

          console.log("project", proj);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [ytd, proj]);

  let emailObj = { email: "" };
  let displayData = "";
  useEffect(() => {
    console.log("priya");
    emailObj = { email: getCookieValue("user") };
    fetch("http://52.146.42.10:9992/api/v1/harvis/firstlogin", {
      method: "PUT",
      body: JSON.stringify(emailObj),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        // Authorization: "Bearer " + getCookieValue("token"),
      },
    })
      .then(
        (response) => response.text()
        // console.log(response,"res")
      )
      .then((data) => {
        if (data.name == "TokenExpiredError") {
          router.push(
            {
              pathname: "/Login",
            },
            "/Login"
          );
        } else {
          console.log(data, "data new api");
          displayData = data;
          if (data == "First Logged in") {
            setCookie("LoginStatus", true, { path: "/" });
            // console.log(getCookieValue("LoginStatus"),"loginstatus")
          } else {
            setCookie("LoginStatus", false, { path: "/" });
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    MixpanelRecord("Revenue Page", {
      message: "Landed on Revenue Page",
    });
    // console.log(displayData,"dd")
  }, []);

  const gotoAcctWise = () => {
    // window.location='/Accountwise.html'
    router.push(
      {
        pathname: "/Accountwise",

        query: { username, policy },
      },

      "/Accountwise"
    );
    MixpanelRecord("Acctwise Init", {
      user: getCookieValue("user"),
    });
  };

  const gotoMonthlywise = () => {
    // window.location='/Monthwise.html'
    router.push(
      {
        pathname: "/Monthwise",

        query: { username, policy },
      },

      "/Monthwise"
    );
    MixpanelRecord("Monthlywise Init", {
      user: getCookieValue("user"),
    });
  };

  return (
    <div
      className="div1"
      style={{ marginTop: -4, width: "98vw", marginLeft: -2 }}
    >
      <div className="grid grid1">
        {/* <div className="col md:col lg:col-5 sm:col">
          <div className="card">
            <Card
              onClick={gotoAcctWise}
              style={{ cursor: "pointer", height: 110 }}
            >
              <div className="grid cards">
                <div className="col-9 md:col-9 lg:col-10">
                  <h4
                    style={{
                      fontFamily: "Didact Gothic",
                      color: "black",
                      fontSize: 18,
                    }}
                  >
                    Accountwise split (YTD)
                  </h4>
                </div>

                <div
                  className="col md:col-3 lg:col-3"
                  style={{ marginLeft: 482,position:"absolute" }}
                >
                  <Button
                    style={{ marginTop: 20 }}
                    className="button1 asp"
                    icon="pi pi-calendar"
                    aria-label="Cart"
                  />
                </div>
              </div>

              <div className="grid card_data1">
                <div className="col md:col lg:col" style={{ marginRight: -20 }}>
                 
                  <Marc></Marc>
                </div>
              </div>

              <div className="grid update">
                <div
                  className="col md:col lg:col-12"
                  style={{
                    marginTop: -15,
                    fontFamily: "Didact Gothic",
                    fontSize: 17,
                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  <h5>Last update on 30 June2023</h5>
                </div>
              </div>
            </Card>
          </div>
        </div> */}

        <div className="col-3 col1 md:col lg:col-4 sm:col">
          <div className="card">
            <Card
              onClick={gotoAcctWise}
              style={{
                cursor: "pointer",
                height: 110,

                marginLeft: 10,
              }}
            >
              <div className="grid cards">
                <div className="col md:col lg:col-10">
                  <h4
                    style={{
                      fontFamily: "Didact Gothic",
                      color: "black",
                      fontSize: 18,
                    }}
                  >
                    Accountwise split (YTD)
                  </h4>
                </div>

                <div className="col-2 md:col-2 lg:col-2">
                  <Button
                    style={{ marginTop: 20, marginLeft: 10 }}
                    className="button"
                    icon="pi pi-calendar"
                    aria-label="Cart"
                  />
                </div>
              </div>

              <div className="grid card_data">
                <div className="col md:col lg:col">
                  <Marc></Marc>
                </div>
              </div>

              {/* <div className="grid card_data1">
                <div className="col" >
                  <b style={{margin:100}}>${ytd}</b>
                 
                </div>

              </div> */}

              <div className="grid update">
                <div
                  className="col md:col lg:col"
                  style={{
                    // marginTop: -20,
                    fontFamily: "Didact Gothic",
                    fontSize: 17,
                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  <h5>Last update on 19 September 2024</h5>
                </div>
              </div>
            </Card>
          </div>
          {/* </Link> */}
        </div>

        <div
          className="col-3 col1 md:col lg:col-4 sm:col"
          style={{ paddingLeft: 0 }}
        >
          <div className="card">
            <Card
              onClick={gotoMonthlywise}
              style={{
                cursor: "pointer",
                height: 110,

                marginLeft: 10,
              }}
            >
              <div className="grid cards">
                <div className="col md:col lg:col">
                  <h4
                    style={{
                      fontFamily: "Didact Gothic",
                      color: "black",
                      fontSize: 18,
                    }}
                  >
                    Monthwise split (YTD)
                  </h4>
                </div>

                <div className="col-2 md:col-2 lg:col-2">
                  <Button
                    style={{ marginTop: 20, marginLeft: 10 }}
                    className="button"
                    icon="pi pi-calendar"
                    aria-label="Cart"
                  />
                </div>
              </div>

              <div className="grid card_data">
                <div className="col md:col lg:col">
                  <b
                    style={{
                      fontFamily: "Didact Gothic",
                      color: "black",
                      fontSize: 16,
                      width: "100px",
                    }}
                  >
                    {formatCurrency(ytd)}
                  </b>
                </div>
              </div>

              {/* <div className="grid card_data1">
                <div className="col" >
                  <b style={{margin:100}}>${ytd}</b>
                 
                </div>

              </div> */}

              <div className="grid update">
                <div
                  className="col md:col lg:col"
                  style={{
                    marginTop: -20,
                    fontFamily: "Didact Gothic",
                    fontSize: 17,
                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  <h5>Last update on 19 September 2024</h5>
                </div>
              </div>
            </Card>
          </div>
          {/* </Link> */}
        </div>

        <div className="col-3 col2 md:col lg:col-4 sm:col">
          <div className="card">
            <Card style={{ height: 110 }}>
              <div className="grid cards">
                <div className="col md:col lg:col">
                  <h4
                    style={{
                      fontFamily: "Didact Gothic",
                      color: "black",
                      fontSize: 18,
                    }}
                  >
                    Projected Revenue-2023
                  </h4>
                </div>

                <div className="col-2 md:col-2 lg:col-2">
                  <Button
                    style={{ marginTop: 20, marginLeft: 10 }}
                    className="button2"
                    icon="pi pi-users"
                    aria-label="Cart"
                  />
                </div>
              </div>

              <div className="grid card_data">
                <div className="col md:col lg:col">
                  <b
                    style={{
                      fontFamily: "Didact Gothic",
                      color: "black",
                      fontSize: 16,
                      width: "100px",
                    }}
                  >
                    {formatCurrency(proj)}
                  </b>
                </div>
              </div>

              <div className="grid update">
                <div
                  className="col md:col lg:col"
                  style={{
                    fontFamily: "Didact Gothic",
                    fontSize: 17,
                    fontWeight: "bold",
                    color: "grey",
                    marginTop: -20,
                  }}
                >
                  <h5>Last update on 19 September 2024</h5>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div className="grid grid2">
        <div className="col-5 md:col-5 lg:col-5 sm:col-5 ">
          <TeamBillability></TeamBillability>
        </div>

        <div className="col-7 md:col-7 lg:col-7 sm:col-7">
          <Card className="heading_card">
            <h2
              className="billing_heading"
              style={{
                marginTop: -4,
                fontFamily: "Didact Gothic",
                color: "black",
                fontSize: 24,
                fontWeight: "900",
              }}
            >
              YTD Achieved vs Projected Revenue Forecast
            </h2>
            {/* <h5 style={{position:"absolute",marginTop:-10,color:"#808080"}}>Last update on 30 April 2023</h5> */}
            <div className="m-0">
              <LineChart></LineChart>
            </div>
          </Card>

          <div className="not_pro">
            <div className="m-0">
              {/* <Notification /> */}
              {/* <Povs></Povs> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
