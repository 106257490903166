import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import "primereact/resources/primereact.min.css";
// import "primereact/resources/themes/lara-dark-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeflex/primeflex.min.css";
import getCookieValue from "../GetCookie";
import { useRouter } from "next/router";
export default function LineDemo() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [dvalue, setdValue] = useState([]);
  const router = useRouter();

  const headers = { Authorization: "Bearer " + getCookieValue("token") };
  useEffect(() => {
    fetch("http://52.146.42.10:9992/api/v1/harvis/billingForecast", { headers })
      .then((response) => response.json())
      .then((data) => {
        if (data.name == "TokenExpiredError") {
          router.push(
            {
              pathname: "/Login",
            },
            "/Login"
          );
        } else {
          console.log("billingForecast" + data);
          setdValue(data);
        }
      })
      .catch((err) => {
        // console.log(err.message);
      });
  }, []);

  useEffect(() => {
    const dataArr1 = [];
    const dataArr2 = [];
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    // console.log("Jan value", dvalue[0]?.Forecast);
    for (let key in dvalue[0]?.SCM) {
      // console.log(dvalue[0].SCM[key]);
      dataArr1.push(dvalue[0].SCM[key]);
    }
    for (let key in dvalue[0]?.Forecast) {
      dataArr2.push(dvalue[0]?.Forecast[key]);
    }

    // console.log("dataArr1" + dataArr1);
    // console.log("dataArr2" + dataArr2);
    const d = new Date();

    let month = d.getMonth();

    for (let i = 11; i >= month; i--) {
      dataArr1.pop();
    }
    // console.log("dataArr1" + dataArr1);
    // console.log(dvalue,"Dvaluee");
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "YTD Achieved",
          data: dataArr1,
          fill: false,
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          tension: 0.4,
        },
        {
          label: "Projected Revenue",
          data: dataArr2,
          fill: false,
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          tension: 0.4,
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };
    // console.log(dvalue, "Dvaluee");
    setChartData(data);
    setChartOptions(options);
  }, [dvalue]);

  return (
    <div className="card">
      <Chart type="line" data={chartData} options={chartOptions} />
    </div>
  );
}
