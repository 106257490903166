/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Marquee from "react-fast-marquee";
import getCookieValue from "../GetCookie";
export default function Marc() {
  let a = 0;
  const [allaccounts, setAllAccounts] = useState([]);
  const marr = [];

  const router = useRouter();
  const headers = { Authorization: "Bearer " + getCookieValue("token") };
  const formatCurrency = (value, val) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });
  };
  useEffect(() => {
    fetch("http://52.146.42.10:9992/api/v1/harvis/getDetails", { headers })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.name == "TokenExpiredError") {
          router.push(
            {
              pathname: "/Login",
            },
            "/Login"
          );
        } else {
          setAllAccounts(data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  // console.log(allaccounts);
  return (
    <div
      className="marcq"
      style={{ position: "relative", top: 77, right: 16 }}
    >
      <Marquee gradient={false} pauseOnHover={true} speed={17} className="com">
        {allaccounts.map((item) => (
          <div className="grid">
            <div className="col">
              <h4
                className="mb-1"
                style={{
                  fontFamily: "Didact Gothic",
                  color: "black",
                  fontSize: 16,
                }}
              >
                {item.companyName}
              </h4>
            </div>

            <div className="col">
              <h4
                className="mb-1"
                style={{
                  fontFamily: "Didact Gothic",
                  color: "black",
                  fontSize: 16,
                  width: "100px",
                }}
              >
                {formatCurrency(item.projected)}
              </h4>
            </div>
          </div>
        ))}
      </Marquee>
    </div>
  );
}
